<template>
  <div class="readme-article">
    <h1 id="用户组权限">
      二、用户组权限
    </h1>
    <h2 id="用户组设置">1.用户组设置</h2>
    <h3 id="用户组列表">1.1用户组列表</h3>
    <p>查看用户组列表，包含用户昵称、用户级别、管理员可设置权限和删除用户组。</p>
    <p>
      <img src="@/assets/img/staff/2-1.png">
    </p>
    <h3 id="添加用户组">1.2添加用户组</h3>
    <p>管理员可添加用户组，需要选择用户等级、填写用户组名称信息。</p>
    <p>
      <img src="@/assets/img/staff/2-2.png">
    </p>
    <h3 id="设置权限">1.3设置权限</h3>
    <p>管理员可对已添加的用户组编辑权限。</p>
    <p>
      <img src="@/assets/img/staff/2-3.png">
    </p>
  </div>
</template>

<script>
export default {
  name: "Staff2-1",
  data() { 
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>